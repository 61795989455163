.card-body {
  position: relative;
}
.card-body .pie-text {
  width: 105px;
  position: absolute;
  top: 44%;
  bottom: 0;
}
[dir] .card-body .pie-text {
  margin: auto;
}
[dir=ltr] .card-body .pie-text {
  left: 0;
  right: 0;
}
[dir=rtl] .card-body .pie-text {
  right: 0;
  left: 0;
}